import { DEFAULT_LANDING_PAGE, LANDING_PAGES } from 'constants/landing-page';
import React from 'react';
import BaseLandingPage from 'widgets/landing-page/BaseLandingPage';
import SeoComponent from 'widgets/seo/SeoComponent';

import './index.css';

function DataDrivenPage() {
  return (
    <BaseLandingPage
      title={LANDING_PAGES.VISUALIZE.title}
      descripntion={LANDING_PAGES.VISUALIZE.description}
      cta={LANDING_PAGES.VISUALIZE.cta}
    />
  );
}

export default DataDrivenPage;

export function Head() {
  return (
    <SeoComponent
      title={LANDING_PAGES.VISUALIZE.seo.title}
      description={LANDING_PAGES.VISUALIZE.seo.description}
      canonicalUrl="labs.goalskeeper.io/visualize"
    />
  );
}
